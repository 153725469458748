import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import DynamicComponent from "../components/DynamicComponent";

import { useStoryblok } from "../utils/storyblok";
import { Box, Container, Heading, Text, Stack } from "@chakra-ui/react";
import Link from "next/link";
import { getAllPageData } from "../utils/ppmApi";
import { NextSeo } from "next-seo";

interface ComponentProps extends AllPageProps { }

export default function Page404(props:ComponentProps):JSX.Element {
  const [globalState, setGlobalState] = useState<GlobalState>(props.globalState);
  const [story, setStory] = useStoryblok(null, props.locale);

  let content = 
    <Container maxW={'3xl'}>
      <NextSeo
        title={'Page Not Found | Praying Pelican Missions'}
        description={'Oops! Looks like you took a wrong turn somewhere. If you think this is a problem with the site, please let us know. Otherwise, head back over to the home page and try again.'}
      />

      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 4 }}
        py={10}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}>
          404
        </Heading>
        <Heading
          fontWeight={500}
          fontSize={{ base: 'lg' }}
          lineHeight={'110%'}>
          Page Not Found
        </Heading>
        <Stack
          as={Box}
          spacing={2}
        >
          <Text color={'gray.500'}>
            Oops! Looks like you took a wrong turn somewhere.
          </Text>
          <Text color={'gray.500'}>
            If you think this is a problem with the site, please let us know.
          </Text>
          <Text color={'gray.500'}>
            Otherwise, head back over to the <Link href="/">home page</Link> and try again.
          </Text>
        </Stack>
      </Stack>
    </Container>
  ;

  if (story && story.content) {
    content = <DynamicComponent blok={story.content} globalState={globalState} setGlobalState={setGlobalState} locale={props.locale}/>;
  }

  return (
    <Layout locale={props.locale} locales={props.locales} defaultLocale={props.defaultLocale} globalState={globalState} setGlobalState={setGlobalState}>
      {content}
    </Layout>
  );
}

export async function getStaticProps({
  locale,
  locales,
  defaultLocale,
}) {
  // get other page data from storyblok/ppmapi
  const allPageData = await getAllPageData(locale, defaultLocale);

  return {
    props: {
      locale,
      locales,
      defaultLocale,
      globalState: { ...allPageData },
    },
    revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 3600 : 1, // rebuild in 1 hour for production,  1 second for staging
  };
}
